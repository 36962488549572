// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en.json";
import translationAR from "../locales/ar.json";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: { translation: translationEN },
  ar: { translation: translationAR }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    // lng: "en",
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });


export default i18n;