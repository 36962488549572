import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "./style.css";
import "../common/grid-new.css";
import "../common/primary-new.css";
import { faBars, FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hamburger from "hamburger-react";
import { v4 as uuidv4 } from "uuid";
import API_URL from "../configuration/config";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { useMediaQuery } from "@mui/material";
import AudioBufferToWav from "audiobuffer-to-wav";
import speechSynthesis from "speech-synthesis";
import YouTube from "react-youtube";

<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,800;1,700;1,800&display=swap');
</style>;
function ChatBotPage() {
  const BlueLoader = () => (
    <div className="blue-loader-container">
      <div className="blue-loader"></div>
      <div className="blue-loader"></div>
      <div className="blue-loader"></div>
    </div>
  );
  const [isSpeechPlaying, setIsSpeechPlaying] = useState(false);
  const buttonIcon = isSpeechPlaying ? "pause" : "play";
  const [isFetching, setIsFetching] = useState(false);
  const playvideoIcon = "/image/video-play-icon.svg";
  const voiceIcon = "/image/voice-icon.png";
  const stop_audio = "/image/stop_record.png";
  const messagesEndRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [voiceIconSrc, setVoiceIconSrc] = useState(voiceIcon);
  const [recordingTime, setRecordingTime] = useState(0);
  const timer = useRef(null);
  const [conversation, setConversation] = useState([]);
  const [showPlayVoiceButton, setShowPlayVoiceButton] = useState(false);
  const [showYouTubeThumbnail, setShowYouTubeThumbnail] = useState(false);
  const [videoIdToPlay, setVideoIdToPlay] = useState("");
  const renderTranslationButton = () => {
    if (isChatMessageContainerHidden) {
      return (
        <button onClick={toggleLanguage} className="language-switch">
          {buttonText}
        </button>
      );
    }
    return null;
  };


  const startRecording = async () => {
    if (isFetching) {
      console.log("Waiting for the previous API request to complete...");
      return;
    }
  
    setRecordingTime(0);
    timer.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];
      setRecording(true);
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };
  
      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/mka" });
        const arrayBuffer = await audioBlob.arrayBuffer();
        clearInterval(timer.current);
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            text: null,
            type: "user",
            isVoiceInput: true,
            audioData: arrayBuffer,
            isPlaying: false,
          },
        ]);
  
        const reader = new FileReader();
        reader.onload = async () => {
          const arrayBuffer = reader.result;
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          const wavBuffer = AudioBufferToWav(audioBuffer);
          const wavBlob = new Blob([wavBuffer], { type: "audio/wav" });
          const formData = new FormData();
          formData.append("file", wavBlob, "recording.wav");
  
          try {
            const response = await fetch(
              `${API_URL}/${i18n.language === 'en' ? 'english_query' : 'arabic_query'}?session_id=${sessionId}&language=${default_API_language}`,
              {
                method: "POST",
                body: formData,
                headers: {
                  Accept: "application/json",
                },
              }
            );
  
            if (response.ok) {
              const data = await response.json();
              const botAnswer = data.result;
  
              setIsFetching(false); // Set isFetching to false upon successful response
              setQueryHistory([...queryHistory, "Voice Message"]);
              setConversation((prevConversation) => [
                ...prevConversation,
                { text: `${botAnswer}`, type: "bot" },
              ]);
            } else {
              console.error("HTTP error:", response.status);
            }
          } catch (error) {
            console.error("Error sending audio file:", error);
          }
        };
  
        reader.readAsArrayBuffer(audioBlob);
      };
  
      mediaRecorder.start();
      setMediaRecorder(mediaRecorder);
      setVoiceIconSrc(stop_audio);
      console.log("Audio recording Started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setRecording(false);
    }
  };
  


  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      setRecording(false);
      clearInterval(timer.current);
      mediaRecorder.stop();
      setVoiceIconSrc(voiceIcon);
      setMediaRecorder(null);
      setAudioChunks([]);
      setIsLoading(false);
      console.log("Audio recording Stopped");
    }
  };

  useEffect(() => {
    const lastMessage = conversation[conversation.length - 1];
    if (
      lastMessage &&
      (lastMessage.type === "bot" || lastMessage.type === "user") &&
      messagesEndRef.current
    ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);
  const speakMessage = (message) => {
    const links = message.match(/(https?:\/\/[^\s]+)/g) || [];
    const filteredMessage = message.replace(/\((https?:\/\/[^\s]+)\)/g, "");
    if (links.some((link) => /(youtube\.com|youtu\.be|https)/.test(link))) {
      filteredMessage = message;
    }
    const utterance = new SpeechSynthesisUtterance(filteredMessage);
    utterance.lang = i18n.language;
    return utterance;
  };

  const handlePlaceClick = (place) => {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(place)}`, "_blank");
  };

  const renderResponse = (text, agentType) => {
    const links = text.match(/(https?:\/\/[^\s]+)/g) || [];
    const hasYouTubeLinks = links.some((link) =>
      /(youtube\.com|youtu\.be)/.test(link)
    );
  
    const handlePlayVoiceClick = () => {
      const links = text.match(/(https?:\/\/[^\s]+)/g) || [];
      let filteredText = text;
      if (links.some((link) => /(youtube\.com|youtu\.be|https)/.test(link))) {
        links.forEach((link) => {
          filteredText = filteredText.replace(link, "");
        });
      }
      if (!isSpeechPlaying) {
        // Start speech synthesis
        const utterance = new SpeechSynthesisUtterance(filteredText);
        utterance.lang = i18n.language;
        window.speechSynthesis.speak(utterance);
        setIsSpeechPlaying(true);
      } else {
        // Pause speech synthesis
        window.speechSynthesis.cancel();
        setIsSpeechPlaying(false);
      }
    };
  
    const handlePlaceClick = (place) => {
      window.open(
        `https://www.google.com/search?q=${encodeURIComponent(place)}`,
        "_blank"
      );
    };
  
    const renderPlacesContainer = () => {
      if (agentType === "PLACES_AGENT") {
        const places = text.split(",").map((place) => place.trim());
        return (
          <div className="places-container">
            {places.map((place, index) => (
              <span
                key={index}
                className="place-clickable"
                onClick={() => handlePlaceClick(place)}
              >
                {place}
              </span>
            ))}
          </div>
        );
      }
      return null;
    };
  
    const renderImages = () => {
      const imageLinks = links.filter((link) =>
        /\.(png|jpg|jpeg)$/i.test(link)
      );
      if (imageLinks.length > 0) {
        return (
          <div className="image-container">
            {imageLinks.map((imageLink, index) => (
              <img key={index} src={imageLink} alt={`Image ${index}`} />
            ))}
          </div>
        );
      }
      return null;
    };
  
    const renderYouTubeThumbnail = () => {
      const youtubeLinks = links.filter((link) =>
        /(youtube\.com|youtu\.be)/.test(link)
      );
  
      if (youtubeLinks.length > 0) {
        const link = youtubeLinks[0];
        const videoId = link.includes("youtu.be")
          ? link.split("/").pop()
          : new URL(link).searchParams.get("v");
  
        if (videoId) {
          return (
            <div className="youtube-video-container">
              {videoIdToPlay === videoId ? (
                <iframe
                  width="640"
                  height="380"
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                  title="YouTube Video"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="youtube-iframe"
                ></iframe>
              ) : (
                <div
                  className="video-thumbnail"
                  style={{
                    backgroundImage: `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`,
                  }}
                >
                  <div className="video-overlay">
                    <button
                      className="play-button"
                      onClick={() => setVideoIdToPlay(videoId)}
                    >
                      <img src={playvideoIcon} alt="Play Button" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        }
      }
      return null;
    };
  
    return (
      <div>
        {agentType === "PLACES_AGENT" ? (
          renderPlacesContainer()
        ) : (
          <ReactMarkdown>{text.replace(/(https?:\/\/[^\s]+)/g, "")}</ReactMarkdown>
        )}
        {renderImages()}
        {renderYouTubeThumbnail()}
        {showPlayVoiceButton && (
          <button onClick={handlePlayVoiceClick} className="play-voice-button">
            <img src="https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/play-audio-icon.png" />
            <p>Listen Now</p>
          </button>
        )}
      </div>
    );
  };
  
  
  

  useEffect(() => {
    const showButtonTimeout = setTimeout(() => {
      setShowPlayVoiceButton(true);
    }, 3000);
    return () => clearTimeout(showButtonTimeout);
  }, [conversation]);

  const handlePlayAudio = (audioUrl) => {
    setIsAudioPlaying(true);
  };
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [playButtonVisible, setPlayButtonVisible] = useState(false);
  const handleAudioStatus = (status) => {
    setIsAudioPlaying(status);
  };
  const [isTyping, setIsTyping] = useState(false);
  const { t } = useTranslation();
  const [userQuestion, setUserQuestion] = useState("");
  const [markdownContent, setMarkdownContent] = useState("");
  const botMessageRef = useRef();
  const [queryHistory, setQueryHistory] = useState([]);
  const [isPromptContainerHidden, setIsPromptContainerHidden] = useState(false);
  const [
    isChatMessageContainerHidden,
    setisChatMessagetContainerHidden,
  ] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isTranslationEnabled, setIsTranslationEnabled] = useState(false);
  const botAvatar = "/image/bot_avatar.png";
  const userAvatar = "/image/user_profile.png";
  const chatIcon = "/assets/chat-send-icon.svg";
  const disablechatIcon = "/assets/disable-chat-icon.svg";
  const [change_language, setChangeLanguage] = useState("ar");
  const [default_language, setDefaultLanguage] = useState("en");
  const [default_API_language, setAPIDefaultLanguage] = useState("EN");
  const [sessionId, setSessionId] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  console.log("Default_api_lang", default_API_language);
  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    const api_lang = default_API_language === "EN" ? "AR" : "EN";
    i18n.changeLanguage(newLanguage);
    setAPIDefaultLanguage(api_lang);
    console.log("changed_api_lang", default_API_language);
  };
  const buttonTextKey = i18n.language === "en" ? "English" : "Arabic";
  const buttonText = i18n.t(buttonTextKey);
  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    const isNewSession = localStorage.getItem("isNewSession");
    if (!isNewSession) {
      const storedChatHistory = localStorage.getItem("chatHistory");
      const storedQueries = localStorage.getItem("queries");
      if (storedChatHistory) {
        setConversation(JSON.parse(storedChatHistory));
      }
      if (storedQueries) {
        setQueryHistory(JSON.parse(storedQueries));
      }
    } else {
      localStorage.setItem("chatHistory", JSON.stringify(conversation));
      localStorage.setItem("queries", JSON.stringify(queryHistory));
      localStorage.setItem("isNewSession", "false");
    }
  }, []);

  // Update the chat history and queries whenever they change
  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(conversation));
    localStorage.setItem("queries", JSON.stringify(queryHistory));
  }, [conversation, queryHistory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    setIsPromptContainerHidden(true);
    setisChatMessagetContainerHidden(false);

    if (!userQuestion) {
      setIsLoading(false);
      return;
    }

    const newConversation = [...conversation];
    newConversation.push({ text: userQuestion, type: "user" });
    setConversation(newConversation);

    const newQueries = [...queryHistory, userQuestion];
    setQueryHistory(newQueries);

    try {
      const apiUrl = `${API_URL}/${i18n.language === 'en' ? 'english_query' : 'arabic_query'}?session_id=${sessionId}&language=${default_API_language}&question=${encodeURIComponent(
        userQuestion
      )}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        if (data && data.result) {
          const userQuery = data.question;
          const answer = data.result;
          const agentType = data.agent_type;
          // answer = answer.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
          setMarkdownContent(answer);
          const words = answer.split(" ");

          const newConversation = [...conversation];
          const botMessage = {
            text: "",
            type: "bot",
            agent_type: agentType,
          };
          newConversation.push({
            text: userQuery,
            type: "user",
          });
          // newConversation.push({
          //   text: answer,
          //   type: 'bot',
          // });
          const displayAnswer = async () => {
            setIsTyping(true);
            for (const word of words) {
              botMessage.text += word + " ";
              newConversation[newConversation.length - 1] = { ...botMessage };
              setConversation([...newConversation]);
              scrollToBottom();
              await new Promise((resolve) => setTimeout(resolve, 70));
            }
            setIsTyping(false);
          };

          setConversation(newConversation);
          scrollToBottom();
          newConversation.push(botMessage);
          displayAnswer();
          speakMessage(answer);
        } else {
          console.error("API response is missing the expected data structure.");
        }
      } else {
        console.error("Error fetching data from API:", response.status);
      }
    } catch (error) {
      console.error("Error sending message to API:", error);
    } finally {
      setIsFetching(false);
    }

    setQueryHistory([...queryHistory, userQuestion]);
    setUserQuestion("");
  };


  useEffect(() => {
    if (botMessageRef.current) {
      botMessageRef.current.classList.add("animate");
      setTimeout(() => {
        typewriterEffect(botMessageRef.current);
        setPlayButtonVisible(true);
      }, 15);
    }
  }, [conversation]);

  const typewriterEffect = (element) => {
    const text = element.innerText;
    element.innerText = "";

    let charIndex = 0;
    const typingInterval = setInterval(() => {
      if (charIndex < text.length) {
        const currentChar = text.charAt(charIndex);
        if (currentChar === " ") {
          element.innerHTML += "&nbsp;";
        } else {
          element.innerText += currentChar;
        }
        charIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 70);
  };

  const toggleMenu = () => {
    setIsNavOpen(!isNavOpen);
    console.log("isNavOpen:", isNavOpen);
  };
  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };
  const isSendButtonDisabled = () => {
    return userQuestion.trim() === "";
  };

  useEffect(() => {
    if (botMessageRef.current) {
      botMessageRef.current.classList.add("animate");
      setTimeout(() => {
        typewriterEffect(botMessageRef.current);
        speakMessage(botMessageRef.current.innerText);
      }, 15);
    }
  }, [conversation]);

  useEffect(() => {
    // Simulating the text rendering process with a setTimeout
    const renderTextTimeout = setTimeout(() => {
      setShowYouTubeThumbnail(true);
    }, 3000);

    return () => clearTimeout(renderTextTimeout);
  }, [conversation]);

  return (
    <div className="full-screen">
      <div className="container">
        <div className="header">
          <Hamburger
            toggled={isNavOpen}
            toggle={setIsNavOpen}
            onToggle={(toggled) => {}}
          />
          <a href="/">
            <img src="/image/Dubai_logo.png" alt="Logo" />
          </a>
          {renderTranslationButton()}

          {/* <button onClick={to}>
        {i18n.language === 'ar' ? 'Switch to English' : 'تبديل إلى العربية'}
      </button> */}
        </div>

        <div className="nav_chat">
          {/* Render navigation panel in larger screens */}
          {isNavOpen && (
            <div className="navigation-panel">
              <div className="navigation_header">
                <p>Search History</p>
              </div>
              <div className="navigation-panel-list">
                {queryHistory.map((query, index) => (
                  <div
                    key={index}
                    className="query-history-item"
                    onClick={() => setUserQuestion(query)}
                  >
                    <div className="query_history_item_logo">
                      <img
                        src="/image/query-history-icon.svg"
                        alt="query_history_logo"
                      ></img>
                    </div>
                    <p style={{ whiteSpace: "nowrap" }}>
                      {query
                        .split(" ")
                        .slice(0, 3)
                        .join(" ")}
                      {query.split(" ").length > 3 ? "..." : ""}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="chat-container">
            {!isPromptContainerHidden && (
              <div className="Prompt-Container">
                <div className="head_text_img_container">
                  <div className="head_text">
                    <div className="heading_navigation">
                      <h1 className="default-cards-h1">{t("greeting")}</h1>
                    </div>
                  </div>
                  <div className="head_text_img">
                    <img
                      src="/image/header_doctor.png"
                      alt=""
                      className="doctor_img"
                    ></img>
                  </div>
                </div>

                <div className="cards-wrapper">
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="cards">
                        <h3>{t("firstAidTitle")}</h3>
                        <p>{t("firstAidDescription")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="cards">
                        <h3>{t("heartAttackTitle")}</h3>
                        <p>{t("heartAttackDescription")}.</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="cards">
                        <h3>{t("cprGuidelines")}</h3>
                        <p>{t("cprGuidelinesDescription")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isChatMessageContainerHidden && showYouTubeThumbnail && (
              <div className="chat-messages">
                {conversation.map((message, index) => (
                  <div className="messages-align" key={index}>
                    <div className="avatar">
                      {message.type === "user" ? (
                        <img src={userAvatar} alt="User Avatar" />
                      ) : (
                        <img src={botAvatar} alt="Bot Avatar" />
                      )}
                    </div>
                    <div
                      className={`message ${
                        message.type === "user" ? "user" : "bot"
                      }`}
                    >
                      <div className="message-content">
                        {message.type === "bot" ? (
                          renderResponse(message.text, message.agent_type)
                        ) : (
                          <p>{message.text}</p>
                        )}
                      </div>

                      <div className="message-content">
                        {message.isVoiceInput && (
                          <div>
                            <audio
                              className="custom-audio-player"
                              src={URL.createObjectURL(
                                new Blob([message.audioData], {
                                  type: "audio/wav",
                                })
                              )}
                              controls
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef}></div>
              </div>
            )}

            <form onSubmit={handleSubmit} className="chat-input">
              <div className="input-container">
                <input
                  type="text"
                  placeholder={
                    recording
                      ? `Voice Recording Started : ${recordingTime} seconds`
                      : "Enter your query here"
                  }
                  value={userQuestion}
                  onChange={(e) => setUserQuestion(e.target.value)}
                />
                <img
                  src={voiceIconSrc}
                  className={`voice-icon ${recording ? "active" : ""}`}
                  alt="Voice Input Icon"
                  onClick={recording ? stopRecording : startRecording}
                />
                <button
                  className={`chat-input-button${
                    isSendButtonDisabled() ? " disabled" : ""
                  }`}
                  type="submit"
                  disabled={isSendButtonDisabled()}
                >
                  {userQuestion.trim() === "" ? (
                    <img
                      src={disablechatIcon}
                      className="send-icon-empty"
                      alt="Empty Chat Icon"
                    />
                  ) : (
                    <img src={chatIcon} className="send-icon" alt="Send Icon" />
                  )}
                </button>
              </div>
              {isPromptContainerHidden && isFetching && <BlueLoader />}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChatBotPage;