import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Login from './components/login.js';
import ChatBotPage from './components/ChatBotPage';

export default function App() {
  return (
<BrowserRouter>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/chat-bot" element={<ChatBotPage />} />
        </Routes>
      </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

